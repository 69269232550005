import { Route, Routes } from 'react-router-dom'
import Layout from '../Layout/Layout'
import IdmeThankYou from '../Pages/IdmeThankYou/idmeThankYou';
import RenderForm from '../Pages/RenderForm/RenderForm'

const Router = () => {
    return (
        <Routes>
        <Route path="/forms/:formId" element={<Layout><RenderForm/></Layout>} />
        <Route path="/idme" element={<Layout><IdmeThankYou/></Layout>} />
        </Routes>
    )
}

export default Router