import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Form } from '@formio/react';

interface QueryParams {
  contact_id?: string;
  matter_id?: string;
}

const CatchAllForm: React.FC = () => {
    const { formId } = useParams<Record<string, string>>();
    const location = useLocation();

  // Parse query parameters
  const queryParams = new URLSearchParams(location.search);
  const contactId = queryParams.get('contact_id') || '';
  const matterId = queryParams.get('matter_id') || '';

  const formUrl = `https://form-builder.tekmir-map.ai/unnbvbmdhxanfum/${formId}`;

  const handleSubmit = (submission: Record<string, any>) => {
    console.log('Form submission:', submission);
    console.log('Contact ID:', contactId);
    console.log('Matter ID:', matterId);
  };

  return (
    <div>
      <Form
        src={formUrl}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default CatchAllForm;
